<template>
  <div class="delivered" style="text-align: left;">
    <van-nav-bar
        title="待送货"
        left-text="返回"
        left-arrow
        @click-left="onReturn"
    />

    <van-list
        v-model="loading"
        v-if="!isDataEmpty"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <van-cell v-for="(item,index) in list" :key="index" :title="item.createTime" is-link :to="'orderDetail?orderNo='+item.orderNo">
        <template #label>
          <van-icon :name="icon_huanhuo" v-if="item.orderType == 4 || item.orderType == 5" />
          <span style="font-size: 18px;color: #565555;">{{item.orderTitle}}</span>
          <van-divider style="margin: 10px 0;" />
          <span style="font-size: 15px;line-height: 30px;">{{item.orderNo}}</span>
          <van-button v-if="item.orderStatus == 2" plain type="warning" size="small" style="float: right;" @click="confirmSend(item.orderNo);">确认送货</van-button>
          <van-button v-if="item.orderStatus != 6 && item.returnStatus == 0" type="info" size="small" style="float: right;margin-right: 5px;" @click="returnGoods(item.orderNo);">取消订货</van-button>

          <van-button v-if="item.orderStatus == 6 && item.timeoutStatus == 0" plain type="warning" size="small" style="float: right;" @click="timeOutStatusChange(item.orderNo,2);">确认收货</van-button>
          <van-button v-if="item.orderStatus == 6 && item.timeoutStatus == 0" type="info" size="small" style="float: right;margin-right: 5px;" @click="timeOutStatusChange(item.orderNo,1);">取消订货</van-button>

          
          <van-button plain type="info" size="small" :icon="icon_navigation" style="float: right;margin-right: 5px;" @click="toStoreMap(item.address);">导航</van-button>
        </template>
      </van-cell>
    </van-list>

    <van-empty description="没有数据哦" v-if="isDataEmpty" />
  </div>
</template>

<script>
// import { getInfo } from "@/api/login";
import icon_navigation from "@/assets/navigation.png";
import icon_huanhuo  from "@/assets/icon_huanhuo.png";
import {getOrderList,confirmSend,returnGoods,timeOutStatus} from "@/api/order";
import {Toast} from "vant";

export default {
  name: "Delivered",
  components: {},
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      icon_navigation: icon_navigation,
      icon_huanhuo: icon_huanhuo,
      pageNo: 0,
      pageSize: 10,
      isDataEmpty: false,
    };
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
    },
    onLoad() {
      this.pageNo++;
      getOrderList(4, this.pageNo, this.pageSize).then(response => {
        console.log(response.data);
        let data = response.data;
        // 加载状态结束
        this.loading = false;
        // 没有数据
        if(data.total == 0) {
          this.isDataEmpty = true;
          this.finished = true;
        } else {
          for(let i in data.records) {
            let item = data.records[i];
            console.log(item);
            this.list.push({timeoutStatus:item.timeoutStatus,returnStatus:item.returnStatus,orderStatus: item.orderStatus,orderNo:item.orderNo, createTime: item.createTime.replace('T',' '), orderTitle: item.orderTitle, address: item.address, orderType: item.orderType});
          }
          if(data.current == data.pages) {
            // 数据全部加载完成
            this.finished = true;
          }
        }
      });
    },
    confirmSend(orderNo) {
      event.stopPropagation();
      confirmSend(orderNo).then(r => {
        console.log(r);
        Toast.success('确认送货成功');
        this.list = [];
        this.pageNo = 0;
        this.isDataEmpty = false;
        this.loading = false;
        this.finished = false;
      });
    },

    returnGoods(orderNo) {
        event.stopPropagation();
        returnGoods(orderNo).then(r => {
            console.log(r);
            Toast.success('取消成功');
            this.list = [];
            this.pageNo = 0;
            this.isDataEmpty = false;
            this.loading = false;
            this.finished = false;
        })
    },

    timeOutStatusChange(orderNo,timeoutStatus) {
        event.stopPropagation();
        let text = timeoutStatus == 1 ? "取消" : "确认收货";
        timeOutStatus({id:orderNo,timeoutStatus:timeoutStatus}).then(r => {
            console.log(r);
            Toast.success(text + '成功，待审核');
            this.list = [];
            this.pageNo = 0;
            this.isDataEmpty = false;
            this.loading = false;
            this.finished = false;
        })
    },

    toStoreMap(address) {
      event.stopPropagation();
      // this.$router.push({path: '/storeMap',query: { address: address }});
      console.log(address);
      // this.$router.push({path: '/storeMapNav',query: { address: address }});
      location.href = 'https://apis.map.qq.com/uri/v1/routeplan?type=drive&to=' + address + '&policy=0&referer=sn-csdlot';
    },
    onReturn() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="scss" scoped>
.delivered {
  background: #f5f5f5;
}
.van-cell__title > span {
 color:#a5a0a0;
}
.van-cell {
  font-size: 16px;
  margin-bottom: 10px;
}
.van-icon__image {
  width: 1.2em;
  height: 1.2em;
}
.van-button--small {
  font-size: 14px;
}
</style>
